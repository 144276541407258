// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-graph-index-tsx": () => import("./../src/components/Graph/index.tsx" /* webpackChunkName: "component---src-components-graph-index-tsx" */),
  "component---src-components-material-post-index-tsx": () => import("./../src/components/MaterialPost/index.tsx" /* webpackChunkName: "component---src-components-material-post-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-discussion-redirect-tsx": () => import("./../src/pages/discussion/redirect.tsx" /* webpackChunkName: "component---src-pages-discussion-redirect-tsx" */),
  "component---src-pages-discussion-test-tsx": () => import("./../src/pages/discussion/test.tsx" /* webpackChunkName: "component---src-pages-discussion-test-tsx" */),
  "component---src-pages-dyskusje-tsx": () => import("./../src/pages/dyskusje.tsx" /* webpackChunkName: "component---src-pages-dyskusje-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-materialy-tsx": () => import("./../src/pages/materiały.tsx" /* webpackChunkName: "component---src-pages-materialy-tsx" */),
  "component---src-pages-projekt-dzialania-tsx": () => import("./../src/pages/projekt/działania.tsx" /* webpackChunkName: "component---src-pages-projekt-dzialania-tsx" */),
  "component---src-pages-projekt-idea-tsx": () => import("./../src/pages/projekt/idea.tsx" /* webpackChunkName: "component---src-pages-projekt-idea-tsx" */),
  "component---src-pages-projekt-tsx": () => import("./../src/pages/projekt.tsx" /* webpackChunkName: "component---src-pages-projekt-tsx" */),
  "component---src-pages-projekt-zespol-tsx": () => import("./../src/pages/projekt/zespół.tsx" /* webpackChunkName: "component---src-pages-projekt-zespol-tsx" */),
  "component---src-pages-warsztaty-dzien-1-tsx": () => import("./../src/pages/warsztaty-dzien-1.tsx" /* webpackChunkName: "component---src-pages-warsztaty-dzien-1-tsx" */)
}

